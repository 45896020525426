<template>
	<b-container class="w-100">
		<div class="header text-center">
			<div class="h1 mb-3">
				DMARC Reports
			</div>
			<div class="lead">
				In tristique ligula vitae purus porta sagittis. Ut vel porta odio.
			</div>
		</div>
		<Chart />
	</b-container>
</template>

<script>
import Chart from'@/components/Chart'

export default {
	name: 'Dmarc',
	components: {
		Chart
	},
};
</script>

<style lang="scss">
	.container {
		max-width: var(--container-lg);
		margin: auto;
		padding: 0 1.5rem 4rem;
	}

	.header {
		margin: 4rem 0;
	}

	.fa {
		color: #000;
		font-size: 50px;
	}

	.fa:hover {
		color: #e60062;
		transform: scale(1.2);
	}

	.card {
		border-radius: 10px;
		box-shadow: 1px 1px 50px #ccc;
	}

	.card-title {
		font-weight: 400;
		font-size: 50px;
	}

	.card-subtitle {
		font-weight: 100;
		font-size: 20px;
	}

	.card-header {
		border-radius: 10px 10px 0px 0px!important;
		color: #fff;
		background: #e60062;
	}

	.footer {
		font-weight: 100;
	}

	.code {
		border: solid 1px #e60062;
		border-radius: 10px;
		padding: 10px;
	}
</style>
