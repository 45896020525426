<template>
<div>
    <div class="table-data">
        <b-table :fixed="fixed" :items="tableData" :fields="fieldsTable" @row-clicked="showRowDetails">
            <template #row-details="row">
                <b-tr>
                    <b-tr v-for="data in row.item.row_details" :key="data.id">
                        <b-td>{{ data.ip }}</b-td>
                        <b-tr v-for="value in data.dkim" :key="value.id">
                            <b-td>{{ value[0] }}</b-td>
                            <b-td>{{ value[1] }}</b-td>
                            <b-td>{{ value[2] }}</b-td>
                        </b-tr>
                        <b-td v-for="value in data.spf" :key="value.id">
                            {{ value[0] }}
                        </b-td>
                        <b-td v-for="value in data.spf" :key="value.id">
                            {{ value[1] }}
                        </b-td>
                    </b-tr>
                </b-tr>
            </template>

            <template #cell(headerForm)="row">
                <div class="d-flex">
					<div class="mr-2">
                        <span class="material-icons-outlined">expand_more</span>
                    </div>
                    <div>
                        {{ row.value }}
                    </div>
                </div>
            </template>

            <template #cell(emailVolume)="row">
                {{ row.value }}
            </template>

            <template #cell(dmarcCompliance)>
                <b-progress :value="pctValue" show-progress></b-progress>
            </template>

            <template #cell(dkim)="row">
                <b-progress show-value :max="max" class="mb-5">
                    <b-progress-bar variant="success" :value="row.item.dkim.pass" id="dkimPass"></b-progress-bar>
                    <b-progress-bar variant="danger" :value="row.item.dkim.fail" id="dkimFail"></b-progress-bar>
                </b-progress>
                <b-popover target="dkimPass" triggers="hover" placement="top">
                    {{ row.item.dkim.pass }}% pass
                </b-popover>
                <b-popover target="dkimFail" triggers="hover" placement="top">
                    {{ row.item.dkim.fail }}% fail
                </b-popover>
            </template>

            <template #cell(spf)="row">
                <b-progress show-value :max="max" class="mb-5">
                    <b-progress-bar variant="success" :value="row.item.spf.pass" id="spfPass"></b-progress-bar>
                    <b-progress-bar variant="danger" :value="row.item.spf.fail" id="spfFail"></b-progress-bar>
                </b-progress>
                <b-popover target="spfPass" triggers="hover" placement="top">
                    {{ row.item.spf.pass }}% pass
                </b-popover>
                <b-popover target="spfFail" triggers="hover" placement="top">
                    {{ row.item.spf.fail }}% fail
                </b-popover>
            </template>
        </b-table>
    </div>
</div>
</template>

<script>
export default {
    name: "Chart",
    data() {
        return {
            dmarcJson: [],
            tableData: [],
            fieldsTable: [{
                    key: "headerForm",
                    label: "Sending Source"
                },
                {
                    key: "emailVolume",
                    label: "Volume"
                },
                {
                    key: "dmarcCompliance",
                    label: "DMARC compliance %"
                },
                {
                    key: "dkim",
                    label: "DKIM Verification (%)"
                },
                {
                    key: "spf",
                    label: "SPF Verification (%)"
                },
            ],
            fixed: "fixed",
            pctValue: 0,
        };
    },
    computed: {
        Dmarc() {
            return this.$FeathersVuex.api.Dmarc;
        },
    },
    methods: {
        async findDmarc() {
            this.dmarcJson = await this.Dmarc.find();
            this.getData();
        },

        getData() {
            /* Update pct data */
            this.pctValue = parseInt(
                this.dmarcJson[0].feedback.policy_published.pct._text,
                10
            );

            /* Update tablaData */
            this.tableData = this.getTableData(this.dmarcJson[0]);
        },

        getTableData(jsonFile) {
            let tableDataObject = new Array();

            jsonFile.feedback.record.forEach((element) => {
                let $headerForm = element.identifiers.header_from._text;
                let $emailCount = element.row.count._text;
                let $dkim = element.row.policy_evaluated.dkim._text;
                let $spf = element.row.policy_evaluated.spf._text;

                const k = tableDataObject.findIndex(
                    (element) => element.headerForm === $headerForm
                );
                if (k >= 0) {
                    tableDataObject[k].emailVolume += parseInt($emailCount, 10);
                    tableDataObject[k].row_details.push(
                        this.getRowDetails(element)
                    );
                    $dkim == "pass" ?
                        tableDataObject[k].dkim.pass++
                        :
                        tableDataObject[k].dkim.fail++;
                    $spf == "pass" ?
                        tableDataObject[k].spf.pass++
                        :
                        tableDataObject[k].spf.fail++;
                } else {
                    tableDataObject.push({
                        headerForm: $headerForm,
                        emailVolume: parseInt($emailCount, 10),
                        dkim: $dkim == "pass" ?
                            {
                                pass: 1,
                                fail: 0
                            } :
                            {
                                pass: 0,
                                fail: 1
                            },
                        spf: $spf == "pass" ?
                            {
                                pass: 1,
                                fail: 0
                            } :
                            {
                                pass: 0,
                                fail: 1
                            },
                        row_details: [this.getRowDetails(element)],
                    });
                }
            });
            return this.changePercentValue(tableDataObject);
        },

        getRowDetails(data) {
            let arrayDkim = new Array(); //[domain,result,selector]
            let arraySpf = new Array(); //[domain,result]
            let rowDetailsObject = new Object();
            let $domain, $result, $selector, $sourceIp;

            $sourceIp = data.row.source_ip._text;

            data.auth_results.dkim.forEach((element) => {
                $domain = element.domain._text;
                $result = element.result._text;
                $selector = element.selector._text;
                arrayDkim.push([$domain, $result, $selector]);
            });

            $domain = data.auth_results.spf.domain._text;
            $result = data.auth_results.spf.result._text;
            arraySpf.push([$domain, $result]);

            rowDetailsObject = {
                ip: $sourceIp,
                dkim: arrayDkim,
                spf: arraySpf,
            };

            return rowDetailsObject;
        },

        showRowDetails(item) {
            this.$set(item, "_showDetails", !item._showDetails);
        },

        changePercentValue(data) {
            for (let i in data) {
                let total = data[i].dkim.pass + data[i].dkim.fail;

                data[i].dkim = {
                    pass: Math.round((data[i].dkim.pass * 100) / total),
                    fail: Math.round((data[i].dkim.fail * 100) / total),
                };

                total = data[i].spf.pass + data[i].spf.fail;
                data[i].spf = {
                    pass: Math.round((data[i].spf.pass * 100) / total),
                    fail: Math.round((data[i].spf.fail * 100) / total),
                };
            }
            return data;
        },
    },
    mounted() {
        this.findDmarc();
    },
};
</script>

<style lang="scss">
.table-data {
    padding: 2rem;
    border-radius: var(--property-border-radius);
    box-shadow: var(--property-box-shadow);
    background-color: var(--color-white);
}

.table {
    margin: 0;
    tr {
        cursor: pointer;
        &:nth-child(even) {
            background-color: #eee;
        }
    }

    th {
        border: 0 !important;
        padding: 0 1rem 1rem;
        font-size: 0.825rem;
    }

    td {
        padding: 1rem;
		font-weight: var(--font-weight-bold);
		font-size: 0.825rem;
    }
}


</style>
